import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { styles } from '../styles/shared-styles';

@customElement('app-home')
export class AppHome extends LitElement {

  static styles = [styles, css`
    main {
      display: grid;
      height: 100%;
      grid-template-columns: min-content minmax(0, 1fr);
      grid-template-rows: 50px minmax(0, 1fr) minmax(0, auto);
      overscroll-behavior: none;
    }
    app-header {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
    }
    app-nav {
      display: none; /* todo: responsive layout for small screens */
    }

    @media (min-width: 700px) {
      app-nav {
        display: block;

        overflow: auto;
        resize: horizontal;

        min-width: 120px;
        max-width: 40vw;
        width: 300px;
      }
    }

    app-content {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }
    play-bar {
      grid-row: 3 / 4;
      grid-column: 1 / 3;
    }
    `];

  render() {
    return html`
      <main>
        <app-header></app-header>
        <app-nav></app-nav>
        <app-content></app-content>
        <play-bar></play-bar>
      </main>
    `;
  }
}
